export const lowercaseExceptions = ['a', 'an', 'and', 'as', 'at', 'by', 'for', 'from', 'in', 'of', 'off', 'on', 'or', 'the', 'to', 'be', 'is'];
export const uppercaseExceptions = ['BBC', 'BBFC', 'NASA', 'NATO', 'FBI', 'CIA', 'CSI', 'AVP', 'AVPR', 'WWE', 'AI', 'DIY', 'ASAP', 'RSVP', 'TBD', 'DM', 'BFF', 'LOL', 'OMG', 'ROFL', 'YOLO', 'ADHD', 'AIDS', 'HIV', 'DNA', 'RNA', 'RIP', 'SOS', 'HBD', 'BC'];
export const contractions = ['\'s', '\'m', '\'ve', '\'re', '\'ll', '\'d', 'n\'t'];

const longestContraction = contractions.reduce((longest, contraction) => (contraction.length > longest ? contraction.length : longest), 0);
export const handleLowerCaseExceptions = (text = '') => {
  if (lowercaseExceptions.includes(text.toLowerCase())) {
    return text.toLowerCase();
  }
  return text;
};

export const handleUpperCaseExceptions = (text = '') => {
  if (uppercaseExceptions.includes(text.toUpperCase())) {
    return text.toUpperCase();
  }
  return text;
};

export const handleRomanNumerals = (text = '') => {
  if (text.match(/^[IVXLCDM]+$/i)) {
    return text.toUpperCase();
  }
  return text;
};

const capitalizeWordWith = (...fns) => (text = '') => fns.reduce((result, fn) => fn(result), text);

export const checkForContractions = (text = '', index = 0) => {
  const foundText = text.slice(index - longestContraction, index);
  return contractions.some((contraction) => foundText.toLowerCase().endsWith(contraction));
};

export const capitalize = (text = '') => text.charAt(0)
  .toUpperCase() + text.slice(1)
  .toLowerCase();

export const titleCase = (text = '') => {
  let result = '';
  let lastSpecialCharacter = '';
  let isFirstWord = true;
  let word = '';

  const firstWordCapitalization = capitalizeWordWith(capitalize, handleUpperCaseExceptions, handleRomanNumerals);
  const innerWordCapitalization = capitalizeWordWith(capitalize, handleLowerCaseExceptions, handleUpperCaseExceptions, handleRomanNumerals);

  for (let i = 0; i < text.length + 1; i++) {
    const char = text[i];
    if (!char || (char && char.match(/[ -/:-@[-`{-~]/))) {
      if (word) {
        if ((char === ' ' || text.length === i) && checkForContractions(text, i)) {
          // Handle contraction cases.
          result += word.toLowerCase();
          word = '';
        } else if (lastSpecialCharacter || isFirstWord) {
          // Handle cases when a word comes after a special character. They should be capitalized.
          result += firstWordCapitalization(word);
          lastSpecialCharacter = '';
          isFirstWord = false;
        } else {
          // Handle inner words. They should be capitalized with exceptions.
          result += innerWordCapitalization(word);
          lastSpecialCharacter = '';
        }
        word = '';
      }
      if (char && char.match(/[.:;*!?<[({&+-]/)) {
        lastSpecialCharacter = char;
      }
      if (char) {
        result += char;
      }
    } else {
      word += char;
    }
  }

  return result;
};
