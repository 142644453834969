import {
  INTEGER_REGEX_MAX_9999,
  TITLE_CASE_METADATA_FIELDS,
} from './constants';
import { titleCase } from './capitalization';

export const hasValue = (input) => input !== undefined && input !== null && input !== '';

export const safeArray = (input) => {
  if (!hasValue(input)) return [];
  if (Array.isArray(input)) return input.filter(hasValue);
  return [input];
};

export const splitToArray = (input) => {
  const array = safeArray(input);
  return array.join(',')
    .split(',')
    .map((e) => e.trim())
    .filter(hasValue);
};

export const arrayToString = (input) => {
  if (!input) return '';
  const array = splitToArray(input);
  return array.join(', ');
};

export const stringToArray = (input) => {
  if (!input) return [];
  if (Array.isArray(input)) return input;
  return input.split(',')
    .map((item) => item.trim())
    .filter(hasValue);
};

export const toNumber = (input) => {
  if (!input) return null;
  const value = Number(input);
  if (Number.isNaN(value) || !INTEGER_REGEX_MAX_9999.test(value)) return 1;
  return value;
};

export const toUpperCaseArray = (input = []) => splitToArray(input).map((item) => item.toUpperCase());

export const titleCaseFields = (obj, fields) => {
  if (!obj || typeof obj === 'string' || !Object.keys(obj).length) return obj;
  if (!fields || !fields.length) return obj;
  return fields.reduce((acc, field) => {
    if (!obj[field]) return acc;
    return {
      ...acc,
      [field]: titleCase(obj[field]),
    };
  }, obj);
};

export const titleCaseSubmissionMetadata = (metadata) => titleCaseFields(metadata, TITLE_CASE_METADATA_FIELDS);
export const titleCaseSubmissionItems = (items) => {
  if (!items || !items.length) return items;
  return items.map((item) => titleCaseFields(item, TITLE_CASE_METADATA_FIELDS));
};
